<template>
  <b-modal :id="`product-modal-${product.id}`" size="sm">
    <div v-if="product && product.product_attributes" class="text-center">
      <div
        class="d-flex justify-content-center mb-1"
        v-if="product.product_attributes.logo"
      >
        <b-img
          :src="product.product_attributes.logo"
          width="150"
          rounded
          class="logo"
        />
      </div>
      <div class="h3 text-primary" v-if="product.product_attributes.name">
        {{ product.product_attributes.name }}
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <b-button
          v-if="!product.product_attributes.is_weighted"
          class="units-btn"
          @click="reduceProductUnits(product)"
        >
          <i class="fas fa-minus" />
        </b-button>
        <input
          id="weight"
          v-model="product.units"
          type="number"
          class="
            bg-transparent
            display-3
            units
            border-1 border-primary
            rounded
            text-center
            input-text
          "
          @click="openModalUpdateStatus()"
        />
        <numpad
          :is-weight="product.product_attributes.is_weighted"
          @ok="bindWeight($event, product)"
        />
        <span
          v-if="product.product_attributes.is_weighted"
          class="display-3 ml-50"
        >
          Kg
        </span>
        <b-button
          v-if="!product.product_attributes.is_weighted"
          class="units-btn"
          @click="addProductUnits(product)"
        >
          <i class="fas fa-plus" />
        </b-button>
      </div>
      <div
        v-if="restockMode === true"
        class="h3 d-flex flex-column align-items-center mt-1 text-primary"
      >
        <b-input-group>
          <label>Precio unitario</label>
          <b-input-group-prepend is-text> $ </b-input-group-prepend>
          <b-form-input id="price" v-model="product.unit_price" trim />
        </b-input-group>
      </div>
      <div
        v-if="restockMode === true"
        class="h3 d-flex flex-column align-items-center mt-1 text-primary"
      >
        <b-input-group>
          <label>Costo unitario</label>
          <b-input-group-prepend is-text> $ </b-input-group-prepend>
          <b-form-input id="cost" v-model="product.unit_cost" trim />
        </b-input-group>
      </div>
    </div>
    <template #modal-footer>
      <div class="d-flex justify-content-center w-100">
        <b-button
          variant="outline-danger"
          class="w-25 m-50"
          @click="deleteNcloseModal()"
        >
          Borrar
        </b-button>
        <b-button variant="primary" class="w-50 m-50" @click="handleOk()">
          OK
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import numpad from "@/@core/components/NumPad.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  components: {
    numpad,
  },
  props: {
    product: {
      type: [Object, null],
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("pos", ["restockMode"]),
  },
  mounted() {},
  methods: {
    openModalUpdateStatus() {
      //this.SET_CASHPAYMENT_MODE(true)
      this.$bvModal.show("cash-numpad");
    },
    ...mapMutations("pos", [
      "deleteProductFromCarts",
      "incrementOneProductQuantity",
      "decrementProductQuantity",
      "SET_CASHPAYMENT_MODE",
    ]),
    ...mapActions("storeProducts", ["editStoreProduct"]),
    ...mapActions("pos", ["getPromotionTotal"]),
    addProductUnits(product) {
      this.incrementOneProductQuantity(product);
      this.getPromotionTotal({ data: [{ ...product }] });
    },
    reduceProductUnits(product) {
      this.decrementProductQuantity(product);
      this.getPromotionTotal({ data: [{ ...product }] });
    },
    deleteNcloseModal() {
      this.deleteProductFromCarts(this.product);
      this.$bvModal.hide(`product-modal-${this.product.id}`);
    },
    bindWeight(units, product) {
      this.product.units = units;
      this.getPromotionTotal({ data: [{ ...product }] });
      this.$bvModal.hide("cash-numpad");
    },
    restrictDecimal() {
      const x = this.product.units.match(/^\d+\.?\d{0,3}/);
      /* eslint-disable-next-line */
      this.product.units = x[0];
    },
    handleOk() {
      if (
        this.product.units === null ||
        this.product.units === 0 ||
        this.product.units === ""
      ) {
        this.deleteNcloseModal();
      }
      if (this.restockMode === true) {
        const productUpdated = {
          id: this.product.id,
          unit_price: this.product.unit_price,
          unit_cost: this.product.unit_cost,
        };
        this.editStoreProduct(productUpdated);
      }
      this.$bvModal.hide(`product-modal-${this.product.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.units-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid white;
  margin: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}
.units {
  min-width: 100px;
}

.logo {
  filter: invert(0.1);
}

.dark-layout {
  .input-text {
    color: white;
  }
}
</style>
