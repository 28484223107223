<template>
  <div>
    <b-card>
      <b-card-title class="d-flex justify-content-between align-items-center">
        <h3>Pedidos automáticos</h3>
        <b-button
          variant="success"
          class="rounded-button"
          :to="{
            name: 'add-store-automatic-restocking',
            params: { id: $route.params.id },
          }"
        >
          <feather-icon icon="PlusIcon" size="20" />
        </b-button>
      </b-card-title>
      <b-row>
        <b-col>
          <automatic-restocking-list
            :cancelRestocking="cancelRestocking"
            :daysInSpanish="daysInSpanish"
            :handleSelectedRestocking="handleSelectedRestocking"
          />
          <automatic-restocking-table
            :cancelRestocking="cancelRestocking"
            :daysInSpanish="daysInSpanish"
            :handleRemoveRestokingProduct="handleRemoveRestokingProduct"
            :handleSelectedRestockingProduct="handleSelectedRestockingProduct"
          />
        </b-col>
      </b-row>
      <div class="mx-2 mb-2 mt-2">
        <pagination
          :loading="loading"
          :entriesPerPage.sync="entriesPerPage"
          :handlePagination="handlePagination"
          :pagination="pagination"
        />
      </div>
    </b-card>

    <automatic-restocking-edit-product
      :selectedRestockingProduct="selectedRestockingProduct"
      :handleEditProduct="handleEditProduct"
    />

    <automatic-restocking-info-modal
      :selectedRestocking="selectedRestocking"
      :handleRemoveRestokingProduct="handleRemoveRestokingProduct"
      :handleSelectedRestockingProduct="handleSelectedRestockingProduct"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"

import AutomaticRestockingNewWizard from "./AutomaticRestockingNewWizard.vue"
import AutomaticRestockingTable from "@/@core/components/automatic-restocking/AutomaticRestockingTable.vue"
import AutomaticRestockingList from "@/@core/components/automatic-restocking/AutomaticRestockingList.vue"
import AutomaticRestockingInfoModal from "@/@core/components/automatic-restocking/AutomaticRestockingInfoModal.vue"
import AutomaticRestockingEditProduct from "@/@core/components/automatic-restocking/AutomaticRestockingEditProduct.vue"
import Pagination from "@/@core/components/Pagination.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  name: "AutomaticRestocking",
  components: {
    Pagination,
    AutomaticRestockingNewWizard,
    AutomaticRestockingTable,
    AutomaticRestockingList,
    AutomaticRestockingInfoModal,
    AutomaticRestockingEditProduct,
    ToastificationContent,
  },
  data() {
    return {
      loading: true,
      entriesPerPage: "10",
      daysInSpanish: {
        monday: "Lunes",
        tuesday: "Martes",
        wednesday: "Miércoles",
        thursday: "Jueves",
        friday: "Viernes",
        saturday: "Sábado",
        sunday: "Domingo",
      },
      selectedRestocking: {},
      selectedRestockingProduct: {},
    }
  },
  watch: {
    entriesPerPage() {
      this.loading = true
      this.fetchAutomaticRestockings({
        by_active_status: "active",
        by_store: this.$route.params.id,
        meta: {
          pagination: {
            page: this.pagination.page,
            per_page: this.entriesPerPage,
          },
        },
      })
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          const msg = error.response.data.messages
          this.$swal({
            title: "ERROR!",
            text: `${msg}`,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          })
        })
    },
  },

  computed: {
    ...mapGetters("automaticRestocking", ["pagination"]),
  },

  created() {
    this.setStoreId(this.$route.params.id)
  },

  beforeMount() {
    this.fetchAutomaticRestockings({
      by_active_status: "active",
      by_store: this.$route.params.id,
      meta: {
        pagination: {
          per_page: this.entriesPerPage,
        },
      },
    }).then((response) => {
      this.loading = false
    })
  },
  methods: {
    ...mapMutations("automaticRestocking", ["setStoreId"]),
    ...mapActions("automaticRestocking", ["fetchAutomaticRestockings"]),
    ...mapActions("automaticRestocking", [
      "cancelAutomaticRestocking",
      "removeRestokingProduct",
      "editRestockingProduct",
    ]),

    handleEditProduct(minimun_stock, threshold_inventory) {
      this.loading = true

      this.editRestockingProduct({
        id: this.selectedRestockingProduct.id,
        automatic_restocking: {
          minimum_stock: this.selectedRestockingProduct.minimum_stock,
          threshold_inventory:
            this.selectedRestockingProduct.threshold_inventory,
        },
      })
        .then((response) => {
          this.$bvModal.hide("edit-restocking-product")
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Producto editado",
              icon: "EditIcon",
              variant: "success",
            },
          })
          this.fetchAutomaticRestockings({
            by_active_status: "active",
            by_store: this.$route.params.id,
            meta: {
              pagination: {
                per_page: this.entriesPerPage,
              },
            },
          }).then((res) => {
            this.selectedRestocking = res.data.find(
              (restocking) => restocking.id === this.selectedRestocking.id
            )
          })
        })
        .catch((error) => {
          this.$swal({
            text: error.response.data.messages[0],
            title: "Edición del producto falló!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          })
        })
        .finally(() => {
          this.loading = false
        })
    },

    handleSelectedRestocking(restocking) {
      this.selectedRestocking = restocking
      this.$bvModal.show("automatic-restocking-info-modal")
    },

    handleSelectedRestockingProduct(restocking_product) {
      this.selectedRestockingProduct = {
        ...restocking_product,
        minimum_stock: parseInt(restocking_product.minimum_stock),
        threshold_inventory: parseInt(restocking_product.threshold_inventory),
      }
      this.$bvModal.show("edit-restocking-product")
    },

    handleRemoveRestokingProduct(restocking_product_id) {
      this.loading = true

      this.$swal({
        title: "¿Estás seguro?",
        text: "El producto se eliminará del pedido automático",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, borrar!",
        cancelButtonText: "No, cancelar!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeRestokingProduct(restocking_product_id)
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Producto eliminado",
                  icon: "TrashIcon",
                  variant: "danger",
                },
              })
              this.fetchAutomaticRestockings({
                by_active_status: "active",
                by_store: this.$route.params.id,
                meta: {
                  pagination: {
                    per_page: this.entriesPerPage,
                  },
                },
              }).then((res) => {
                this.selectedRestocking = res.data.find(
                  (restocking) => restocking.id === this.selectedRestocking.id
                )
              })
            })
            .catch((error) => {
              this.$swal({
                text: error.response.data.messages[0],
                title: "Eliminación del producto falló!",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
                buttonsStyling: false,
              })
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },

    cancelRestocking(restocking_id) {
      this.loading = true
      this.$swal({
        title: "¿Estás seguro?",
        text: "El pedido se desactivará y dejará de ser recibido por su proveedor",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "seee, borralo!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.cancelAutomaticRestocking(restocking_id)
            .then((response) => {
              this.loading = false
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Pedido automático cancelado con exito!",
                  icon: "TrashIcon",
                  variant: "danger",
                },
              })
              this.fetchAutomaticRestockings({
                by_active_status: "active",
                by_store: this.$route.params.id,
                meta: {
                  pagination: {
                    per_page: this.entriesPerPage,
                  },
                },
              })
            })
            .catch((error) => {
              this.$swal({
                text: error.response.data.messages[0],
                title: "Cancelación del pedido automático falló!",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },

    handlePagination({ page, per_page }) {
      this.loading = true
      this.fetchAutomaticRestockings({
        by_active_status: "active",
        by_store: this.$route.params.id,
        meta: {
          pagination: {
            page,
            per_page,
          },
        },
      }).then(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.gutter-sm {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.restocking-table {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.restocking-list {
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
}

.rounded-button {
  border-radius: 50%;
  padding: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
}
</style>
